import React from 'react'

// functional component
const Infocard = (props) =>{

  return(
      <div class="bg-light shadow ms-2 me-2">
      <div class="card-body px-2 py-3">
        <h5 class="card-title ">Overview of the piece</h5>
        <h6 class="card-subtitle m-2 text-muted">Artist: Stephen Roddy</h6>
        <h7 class="card-subtitle m-2 text-primary">Please view on a mobile device and listen with headphones.</h7>

        <div class ="clearfix">
          <p class="card-text mx-auto mt-3">Signal to Noise Loops v5: Breathing Space is a data-driven audiovisual installation for headphone presentation over mobile or smart devices.
            <br />It is informed by principles from the fields of Cybernetics and Artificial Intelligence.

            <br />Visualization and Sonification Techniques were used to turn data collected from noise level sensors at Ranelagh, Bull Island, and Chancery Park into music and visuals.

            <br />The first part of the piece uses data from April 2022, when the initial shock of the COVID-19 pandemic was starting to subside and daily patterns of human interaction were slowly returning to normal across the city.

            <br />The second part uses noise readings from the height of the pandemic in April 2020. Activity across the city was minimal at this point, as networked modes of communication became the dominant paradigms for human interaction.</p>
        </div>
      </div>
    </div>
  )
};

export default Infocard


//https://www.nasa.gov/multimedia/imagegallery/iotd.html
