import React from 'react'

const Navbar = ( props ) => {

return (

  <nav class="navbar mb-2 navbar-light bg-light shadow ms-2 me-2 ">
    <a class="navbar-brand mb-0 ms-2" href="#"><h4>Signal to Noise Loops</h4>
    <h5 class ="text-wrap">V5: Breathing Space</h5></a>
  </nav>
  )
};
export default Navbar
//  <center><h1>NASA:  Picture of the Day</h1></center>
